/**
 * Created by HungPD on 10/03/2016.
 */
function setFilterCookie(filter) {
    $.cookie('filter', filter, {path: '/'});
}

function setCategoryFilter(filter) {
    if ($('#caycanh').length > 0) {

        var filter_block = $('#filters'),
            current_item = filter_block.find('li.current'),
            current_item_active = current_item.find('span.active'),
            new_item_active = filter_block.find('span[data-filter="' + filter + '"]');

        current_item.removeClass('current');
        current_item_active.removeClass('active');
        new_item_active.closest('li').addClass('current');
        new_item_active.addClass('active');

    }
}

function scrollToFilter(filter) {
    if ($('#caycanh').length > 0) {
        // $('html, body').animate({
        //     scrollTop: $('#caycanh').offset().top
        // }, 500);

        setCategoryFilter(filter);
    }
}
var $container = $('#portfoliolist');

$(function () {
    var $url = url(),
        query = url('?', $url);
    var current_filter = $.cookie('filter') !== undefined ? $.cookie('filter') : '.cay-cong-trinh';
    var mixitup_config = {
        selectors: {
            target: '.portfolio',
            filter: '.filter',
            pager: '.pager'
        },
        load: {
            filter: current_filter,
            page: 1
        },
        pagination: {
            limit: 9,
            loop: false,
            generatePagers: true,
            maxPagers: 10,
            pagerClass: '',
            prevButtonHTML: '«',
            nextButtonHTML: '»'
        }
    };

    var filterList = {

        init: function () {

            // MixItUp plugin
            // http://mixitup.io
            $container.mixItUp(mixitup_config);

        }

    };

    // Run the show!
    filterList.init();
    if (query !== undefined){
        var val = query.search,
            state = $container.mixItUp('getState'),
            $filtered = state.$targets.filter(function () {
                var str = $(this).find('.product-name').text().toLowerCase().toString();
                return str.indexOf(val.trim().toLowerCase()) >= 0;
            });
        $container.mixItUp('filter', $filtered);
    }else{
        scrollToFilter(current_filter);
        $container.mixItUp('filter', current_filter);
    }

    $('a[href*="#"]').click(function () {
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 500);
    });

});

$(document).ready(function () {
    $('.item').slick({
        "autoplay": true,
        "arrows": false,
        "autoplaySpeed": 4000,
        "responsive": [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-responsive').slick({
        "autoplay": true,
        "dots": true,
        "infinite": true,
        "arrows": true,
        "autoplaySpeed": 2000,
        "slidesToShow": 4,
        "slidesToScroll": 2,
        "responsive": [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-home').slick({
        "autoplay": true,
        "dots": true,
        "infinite": true,
        "arrows": false,
        "autoplaySpeed": 4000,
        "slidesToShow": 2,
        "slidesToScroll": 1
    });

    $('#filters li span').click(function () {
        $('#filters li').removeClass('current');
        $(this).closest('li').addClass('current');
    });

    $(document).on('click', '.sub-menu li a, .footer-right a', function () {
        var filter = $(this).data('filter');

        setFilterCookie(filter);
        setCategoryFilter(filter);
        $container.mixItUp('filter', filter);
    });

});